import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { AuthService } from '../../../services/logged/auth.service';
import { TempDataService } from '../../../services/temp-data.service';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service';

@Component({
  selector: 'app-simulador-datos',
  templateUrl: './simulador-datos.component.html',
  styleUrls: []
})
export class SimuladorDatosComponent implements OnInit {
  
  title = 'Confirma la simulación de tu inversión';
  formSimulador: FormGroup;
  rendimientoPorcentaje: number = 0;
  calculoMensual: string = '';
  calculoAnual: string = '';
  calculoTotal: string = '';
  capitalInicial: number = 0;
  isLoading: boolean = false;
  escribiendo: boolean = false;
  mensajeRespuesta: string = '';
  envioExitoso: boolean = false;
  isInitialLoad: boolean = true;
  nombreCuentaTemp: any;

  constructor(
    private http: HttpClient,
    private _location: Location,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private tempDataService: TempDataService,
    private progresoUsuarioService: ProgresoUsuarioService
  ) {
    this.formSimulador = this.fb.group({
      monto_inversion: ['', [Validators.required, Validators.min(15000)]],
      plazo_anios: ['', Validators.required],
  });
  }

  ngOnInit(): void {
    this.fetchAccountName();
    this.progresoUsuarioService.checkUserProgressAndNavigate();
    this.fetchInitialData();
    this.formSimulador.valueChanges.subscribe(() => {
      this.escribiendo = true;
      this.calcularRendimientos();
    });
    /* this.tempDataService.clearNombreCuenta(); */
  }

  fetchAccountName() {
    this.isLoading = true;
    const headers = new HttpHeaders({
        'Authorization': this.authService.getToken() || ''
    });

    this.http.get<any[]>('https://loginx.arcsacapital.com/cuentas_get', { headers })
        .pipe(
            tap(data => {
                if (data.length > 0 && data[0].hasOwnProperty('nombreCuenta')) {
                    this.nombreCuentaTemp = data[0].nombreCuenta;
                    console.log('Nombre de cuenta obtenido:', this.nombreCuentaTemp);
                } else {
                    throw new Error('Error: No se encontró el campo nombreCuenta o el array está vacío.');
                }
            }),
            catchError(error => {
                console.error('Error al realizar la solicitud GET:', error);
                return of(null);
            }),
            finalize(() => this.isLoading = false)
        )
        .subscribe({
            next: () => {
                console.log('Operación realizada con éxito.');
            },
            error: (err) => {
                console.error('Error en el proceso de obtención del nombreCuenta:', err);
            }
        });
  }
  

  fetchInitialData() {
    this.isLoading = true;
    this.isInitialLoad = false;
    const headers = new HttpHeaders({
        'Authorization': this.authService.getToken() || ''
    });
    this.http.get<{ monto_inversion: string, plazo_anios: number }[]>('https://loginx.arcsacapital.com/simulador_get', {
        headers: headers,
        observe: 'response'
    }).pipe(
        tap(response => {
            if (response.body && response.status === 200 && response.body.length > 0) {
                const data = response.body[0];
                const formattedMonto = this.formatValueWithUSD(parseFloat(data.monto_inversion));
                this.formSimulador.patchValue({
                    monto_inversion: formattedMonto,
                    plazo_anios: data.plazo_anios
                });
            }
        }),
        catchError((error) => {
            console.error('Error fetching initial data:', error);
            return of(null);
        }),
        finalize(() => this.isLoading = false)
    ).subscribe();
  }

  formatValueWithUSD(value: number): string {
    return `${value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })} USD`;
  }

  onMontoKeydown(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const nonDestructiveKeys = ['ArrowLeft', 'ArrowRight', 'Tab', 'Backspace', 'ArrowUp', 'ArrowDown', 'Control', 'Alt', 'Meta', 'Escape'];
      if (!nonDestructiveKeys.includes(event.key) && /\$\s?|,|\sUSD/.test(inputElement.value)) {
        inputElement.value = '';
        event.preventDefault();
      }
    }
  }

  onMontoFocus(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
  }
  
  onMontoBlur(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      let value = inputElement.value.replace(/\$\s?|,/g, '').trim();
      if (value && !isNaN(parseFloat(value))) {
        this.formSimulador.patchValue({
          monto_inversion: `${parseFloat(value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })} USD`,
        });
      } else {
        this.formSimulador.patchValue({ monto_inversion: '' });
      }
    }
  }

  calcularRendimientos(): void {
    this.isLoading = true;
    this.escribiendo = false;

    setTimeout(() => {
        const monto_inversion = Number(this.formSimulador.get('monto_inversion')?.value.replace(/[^\d.-]/g, ''));
        const plazo_anios = Number(this.formSimulador.get('plazo_anios')?.value);

        if (monto_inversion && plazo_anios) {
            const porcentajeAnual = this.getAnnualPercentage(plazo_anios);
            this.rendimientoPorcentaje = porcentajeAnual;
            const rendimientoTotal = monto_inversion * (porcentajeAnual / 100) * plazo_anios;

            this.calculoTotal = rendimientoTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            this.calculoAnual = (rendimientoTotal / plazo_anios).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            this.calculoMensual = ((rendimientoTotal / plazo_anios) / 12).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }
        this.isLoading = false;
    }, 1000);
  }

  getAnnualPercentage(plazo_anios: number): number {
    const porcentaje: { [key: number]: number } = {
      1: 16.5, 2: 20, 3: 20, 4: 20, 5: 20,
    };
    return porcentaje[plazo_anios] || 0;
  }

  formatCurrency(value: number): string {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });
  }

  capturarRendimientos() {
    if (this.formSimulador.invalid) {
        this.mensajeRespuesta = 'Por favor, completa el formulario correctamente.';
        this.isLoading = false;
        return;
    }

    this.isLoading = true;
    const monto_inversion = Number(this.formSimulador.value.monto_inversion.replace(/[^\d.-]/g, ''));
    const plazo_anios = Number(this.formSimulador.value.plazo_anios);
    const rendimientoPorcentaje = this.getAnnualPercentage(plazo_anios);

    if (!this.nombreCuentaTemp) {
        this.mensajeRespuesta = 'No autorizado o nombreCuenta no disponible.';
        this.isLoading = false;
        return;
    }

    const token = this.authService.getToken();
    if (!token) {
        this.mensajeRespuesta = 'No autorizado. Token no disponible.';
        this.isLoading = false;
        return;
    }

    const headers = new HttpHeaders({'Authorization': token});
    const data = {
        monto_inversion,
        plazo_anios,
        porcentaje: rendimientoPorcentaje,
        nombreCuenta: this.nombreCuentaTemp  // Utiliza el nombreCuenta temporal obtenido
    };

    this.http.post('https://loginx.arcsacapital.com/seccion5', data, { headers, observe: 'response' })
        .pipe(
            tap((response: HttpResponse<any>) => {
                this.mensajeRespuesta = 'Información enviada con éxito.';
                this.envioExitoso = true;
                this.isLoading = false;
                this.router.navigate(['/registro06']);
            }),
            catchError((error) => {
                console.error('Error al enviar los datos:', error);
                this.mensajeRespuesta = error.error.message || 'Error al enviar los datos.';
                this.isLoading = false;
                this.envioExitoso = false;
                return of(null);
            })
        )
        .subscribe();
  }

  continuarSinCambiar(): void {
    this.router.navigate(['/registro06']);
  }

}