import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthService } from '../../services/logged/auth.service';

@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: [],
})

export class SimuladorComponent implements OnInit {
  title = 'Simula tu inversión';
  formSimulador: FormGroup;
  rendimientoPorcentaje: number = 0;
  calculoMensual: number = 0;
  calculoAnual: number = 0;
  calculoTotal: number = 0;
  capitalInicial: number = 0;
  isLoading: boolean = false;
  escribiendo: boolean = false;
  mensajeRespuesta: string = '';
  envioExitoso: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.formSimulador = this.fb.group({
      monto_inversion: ['', [Validators.required, Validators.min(15000)]],
      plazo_anios: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.formSimulador.valueChanges.subscribe(() => {
      this.escribiendo = true;
      this.calcularRendimientos();
    });
  }
  
  get monto_inversion() {
    return this.formSimulador.get('monto_inversion');
  }

  onMontoFocus(event: any): void {
    let value = event.target.value;
    value = value.replace(/\$\s?|(,*)/g, '');
    this.formSimulador.patchValue({ monto_inversion: value });

    this.formSimulador.patchValue({ monto_inversion: '' });
  }

  onMontoBlur(event: any): void {
    let value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      this.formSimulador.patchValue({
        monto_inversion:
          value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }) + ' USD',
      });
    }
  }

  calcularRendimientos(): void {
    this.isLoading = true;
    this.escribiendo = false;

    setTimeout(() => {
      const monto_inversion =
        Number(
          this.formSimulador.value.monto_inversion.replace(/[^\d.-]/g, '')
        ) || 0;
      const plazo_anios = Number(this.formSimulador.value.plazo_anios) || 0;

      if (monto_inversion && plazo_anios) {
        const porcentaje: { [key: number]: number } = {
          1: 16.5,
          2: 20,
          3: 20,
          4: 20,
          5: 20,
        };

        const porcentajeAnual = porcentaje[plazo_anios] || 0;

        this.rendimientoPorcentaje = porcentajeAnual;
        const rendimientoTotal =
          monto_inversion * (porcentajeAnual / 100) * plazo_anios;
        this.calculoTotal = rendimientoTotal;
        this.capitalInicial = monto_inversion;
        this.calculoAnual = rendimientoTotal / plazo_anios;
        this.calculoMensual = this.calculoAnual / 12;

        this.isLoading = false;
      } else {
        this.escribiendo = true;
      }
    }, 1000);
  }

  capturarRendimientos() {
    if (this.formSimulador.invalid) {
      this.mensajeRespuesta = 'Por favor, completa el formulario correctamente.';
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    const data = {
      monto_inversion: Number(this.formSimulador.value.monto_inversion.replace(/[^\d.-]/g, '')),
      plazo_anios: Number(this.formSimulador.value.plazo_anios),
      porcentaje: this.rendimientoPorcentaje
    };

    this.http.post('https://loginx.arcsacapital.com/simulador', data, { observe: 'response' })
      .pipe(
        tap((response: HttpResponse<any>) => {
          this.mensajeRespuesta = 'Información enviada con éxito.';
          this.envioExitoso = true;
          this.isLoading = false;
          this.router.navigate(['/registro01']);
        }),
        catchError((error) => {
          console.error('Error al enviar los datos:', error);
          this.mensajeRespuesta = error.error.message || 'Error al enviar los datos.';
          this.isLoading = false;
          this.envioExitoso = false;
          return of(null);
        })
      )
      .subscribe();
  }
}
