import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TempDataService } from '../../../services/temp-data.service';

interface Movimiento {
  estatus: string | null;
  fecha: string | null;
  monto: string;
  tipo: string;
}

@Component({
  selector: 'app-perfil02',
  templateUrl: './perfil02.component.html',
  styles: []
})
export class Perfil02Component implements OnInit {
  @ViewChild('resultados') resultados!: ElementRef;

  nombreCuenta: string = '';
  inversionInicial: string = '';
  
  fechaInicio: string = '';
  rendimiento: string = '';

  inversionActual: string = '--';
  aumentosCapital: string = '--';
  retiros: string = '--';
  impuestos: string = '--';
  balance: string = '--';

  movimientos: Movimiento[] = [];
  mesAnio: string = '';
  mesesDisponibles: string[] = [];
  mesSeleccionado: string = '';
  datosSeleccionados: Movimiento[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private tempDataService: TempDataService
  ) {}

  ngOnInit(): void {
    const nombreCuentaStored = localStorage.getItem('nombreCuenta');
    this.nombreCuenta = nombreCuentaStored ? nombreCuentaStored : '';
    console.log('Nombre de la cuenta cargado:', this.nombreCuenta);
    if (this.nombreCuenta) {
      this.cargarDetallesInversion(this.nombreCuenta);
      this.cargarDatosCalculadora(this.nombreCuenta);
      this.cargarMesesDisponibles(this.nombreCuenta);
    } else {
      this.obtenerNombreCuenta();
    }
  }

  obtenerNombreCuenta() {
    this.tempDataService.getFormDatosPersonales().subscribe({
      next: (data) => {
        if (data && data.nombreCuenta) {
          this.nombreCuenta = data.nombreCuenta;
          localStorage.setItem('nombreCuenta', this.nombreCuenta);
          console.log('Nombre de la cuenta obtenido:', this.nombreCuenta);
          if (this.nombreCuenta) {
            this.cargarDetallesInversion(this.nombreCuenta);
            this.cargarDatosCalculadora(this.nombreCuenta);
            this.cargarMesesDisponibles(this.nombreCuenta);
          }
        } else {
          console.error('No se encontró nombreCuenta');
          this.router.navigate(['/perfil01']);
        }
      },
      error: (error) => {
        console.error('Error al obtener nombreCuenta', error);
        this.router.navigate(['/perfil01']);
      }
    });
  }

  cargarDetallesInversion(nombreCuenta: string) {
    const url = `https://loginx.arcsacapital.com/inversiones_get?nombreCuenta=${nombreCuenta}`;
    console.log('Consultando detalles de inversión con URL:', url);
    this.http.get<any[]>(url).subscribe({
      next: (response) => {
        console.log('Respuesta de detalles de inversión:', response);
        const cuenta = response.find(c => c.nombreCuenta === nombreCuenta);
        if (cuenta) {
          if (cuenta.monto_inversion) {
            this.inversionInicial = `$${Number(cuenta.monto_inversion).toLocaleString('en-US')}`;
          } else {
            console.error('El campo monto_inversion no está disponible en la respuesta para la cuenta:', nombreCuenta);
          }
          if (cuenta.fecha_inicio) {
            this.fechaInicio = cuenta.fecha_inicio;
          } else {
            console.error('El campo fecha_inicio no está disponible en la respuesta para la cuenta:', nombreCuenta);
          }
        } else {
          console.error('No se encontró la cuenta:', nombreCuenta);
        }
      },
      error: (error) => {
        console.error('Error al obtener los detalles de la inversión', error);
      }
    });
  }

  cargarDatosCalculadora(nombreCuenta: string) {
    const url = `https://loginx.arcsacapital.com/rir_get?nombreCuenta=${nombreCuenta}`;
    console.log('Consultando datos de calculadora con URL:', url);
    this.http.get<Movimiento[]>(url).subscribe({
      next: (response) => {
        console.log('Datos recibidos:', response);
        const ultimosTresMeses = this.obtenerUltimosTresMeses(response);
        this.movimientos = response.filter(mov => ultimosTresMeses.includes(this.obtenerMesAnioDeFecha(mov.fecha)));
        console.log('Movimientos de los últimos tres meses:', this.movimientos);
      },
      error: (error) => {
        console.error('Error al cargar los datos de la calculadora', error);
        this.fechaInicio = 'Error en la carga';
        this.rendimiento = 'Error en la carga';
      }
    });
  }

  cargarMesesDisponibles(nombreCuenta: string) {
    const url = `https://loginx.arcsacapital.com/meses_anos?nombreCuenta=${nombreCuenta}`;
    console.log('Consultando meses disponibles con URL:', url);
    this.http.get<{año: number, mes: number}[]>(url).subscribe({
      next: (response) => {
        this.mesesDisponibles = response.map(entry => `${this.obtenerNombreMes(entry.mes)} ${entry.año}`);
        console.log('Meses disponibles cargados:', this.mesesDisponibles);
      },
      error: (error) => {
        console.error('Error al cargar los meses disponibles', error);
      }
    });
  }

  obtenerUltimosTresMeses(movimientos: Movimiento[]): string[] {
    const fechas = movimientos.map(mov => mov.fecha).filter(fecha => fecha !== null);
    const fechasOrdenadas = fechas.sort((a, b) => new Date(b!).getTime() - new Date(a!).getTime());
    const ultimasTresFechas = fechasOrdenadas.slice(0, 3);
    return ultimasTresFechas.map(fecha => this.obtenerMesAnioDeFecha(fecha));
  }

  obtenerMesAnioDeFecha(fecha: string | null): string {
    if (!fecha) return '';
    const date = new Date(fecha);
    const mes = date.getMonth() + 1; // Enero es 0
    const año = date.getFullYear();
    return `${mes}-${año}`;
  }

  obtenerNombreMes(numeroMes: number): string {
    const meses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return meses[numeroMes - 1];
  }

  consultarPeriodo() {
    if (this.mesSeleccionado) {
      const [mesNombre, año] = this.mesSeleccionado.split(' ');
      const mes = this.getNumeroMes(mesNombre);
      const url = `https://loginx.arcsacapital.com/calculadora?nombreCuenta=${this.nombreCuenta}&mes=${mes}&año=${año}`;
      console.log('Consultando datos del periodo con URL:', url);
      this.http.get<Movimiento[]>(url).subscribe({
        next: (movimientos) => {
          this.datosSeleccionados = movimientos;
          console.log('Datos seleccionados para el periodo:', this.datosSeleccionados);
          this.scrollToResultados();
        },
        error: (error) => {
          console.error('Error al consultar los datos del periodo', error);
        }
      });
    } else {
      console.error('No se ha seleccionado un periodo');
    }
  }

  consultarOtroPeriodo() {
    if (this.mesSeleccionado) {
      const [mesNombre, año] = this.mesSeleccionado.split(' ');
      const mes = this.getNumeroMes(mesNombre);
      const url = `https://loginx.arcsacapital.com/calculadora?nombreCuenta=${this.nombreCuenta}&mes=${mes}&año=${año}`;
      console.log('Consultando datos del periodo con URL:', url);
      this.http.get<any[]>(url).subscribe({
        next: (movimientos) => {
          this.datosSeleccionados = movimientos.map(mov => ({
            tipo: 'Rendimiento', // Asigna el tipo correcto según tu lógica
            estatus: null,
            monto: mov.rendimiento_total,
            fecha: mov.fecha_inicio
          }));
          console.log('Datos seleccionados para el periodo:', this.datosSeleccionados);
        },
        error: (error) => {
          console.error('Error al consultar los datos del periodo', error);
        }
      });
    } else {
      console.error('No se ha seleccionado un periodo');
    }
  }

  getNumeroMes(nombreMes: string): number {
    const meses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return meses.indexOf(nombreMes) + 1;
  }

  scrollToResultados() {
    setTimeout(() => {
      if (this.resultados) {
        this.resultados.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }
}