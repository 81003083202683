import { HttpClient } from '@angular/common/http'; 
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service';

@Component({
  selector: 'app-registro04',
  templateUrl: './registro04.component.html',
  styleUrls: []
})
export class Registro04Component implements OnInit {

  title = "Domicilio";

  formDomicilio: FormGroup;
  
  errorMessage: string | null = null;
  successMessage: string | null = null;

  constructor( 
    private http: HttpClient, 
    private _location: Location, 
    private router: Router, 
    private cdr: ChangeDetectorRef, 
    private progresoUsuarioService: ProgresoUsuarioService
    ) {
    this.formDomicilio = new FormGroup({
      pais: new FormControl('', { validators: [Validators.required]}),
      estado: new FormControl('', { validators: [Validators.required]}),
      calle: new FormControl('', { validators: [Validators.required] }),
      numero: new FormControl('', { validators: [Validators.required]}),
      codigo_postal: new FormControl('', { validators: [Validators.required]}),
    });
  }

  ngOnInit(): void {
    this.progresoUsuarioService.checkUserProgressAndNavigate();
  }

  get pais(){
    return this.formDomicilio.get('pais');
  }
  get estado(){
    return this.formDomicilio.get('estado');
  }
  get calle(){
    return this.formDomicilio.get('calle');
  }
  get numero(){
    return this.formDomicilio.get('numero');
  }
  get codigo_postal(){
    return this.formDomicilio.get('codigo_postal');
  }

  onSubmit() {
    this.errorMessage = null;

    if (this.formDomicilio.valid) {
      const formData = this.formDomicilio.value;
  
      this.http.post('https://loginx.arcsacapital.com/datos_personales/seccion3', formData).subscribe({
        next: (response) => {
          this.successMessage = 'Datos enviados con éxito.';
          setTimeout(() => {
            this.router.navigate(['/registro05']);
          }, 2000);
        },
        error: (error) => {
          if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
          } else {
            this.errorMessage = 'Error al enviar los datos, intente de nuevo más tarde. ERROR 1002';
          }
          this.cdr.markForCheck();
        }
      });
    } else {
      this.errorMessage = 'Por favor, complete todos los campos requeridos.';
      this.cdr.markForCheck();
    }
  }
}
