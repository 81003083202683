import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/logged/auth.service';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TempDataService } from '../../../../services/temp-data.service';

@Component({
  selector: 'app-perfil-aumento01',
  templateUrl: './perfil-aumento01.component.html',
  styles: []
})
export class PerfilAumento01Component implements OnInit {

  title = 'Aumenta tu capital';
  formSimulador: FormGroup;
  rendimientoPorcentaje: number = 0;
  calculoMensual: number = 0;
  calculoAnual: number = 0;
  sumaCapital: number = 0;
  capitalInicial: number = 0;
  isLoading: boolean = false;
  escribiendo: boolean = false;
  mensajeRespuesta: string = '';
  envioExitoso: boolean = false;
  nombreCuenta: string = '';

  constructor(
    private http: HttpClient,
    private _location: Location,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private tempDataService: TempDataService
  ) {
    this.formSimulador = this.fb.group({
      aumentoInversion: ['', [Validators.required, Validators.min(15000)]],
      plazo_anios: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.nombreCuenta = localStorage.getItem('nombreCuenta') || '';
    if (this.nombreCuenta) {
      this.cargarDatosIniciales();
      const tempFormData = this.tempDataService.getCurrentAumentoInversion();
      if (tempFormData) {
        this.formSimulador.patchValue(tempFormData);
      }
      this.formSimulador.valueChanges.subscribe(() => {
        this.escribiendo = true;
        this.tempDataService.setFormAumentoInversion(this.formSimulador.value);
        this.calcularRendimientos();
      });
    } else {
      this.obtenerNombreCuenta();
    }
  }

  obtenerNombreCuenta() {
    this.tempDataService.getFormDatosPersonales().subscribe({
      next: (data) => {
        if (data && data.nombreCuenta) {
          this.nombreCuenta = data.nombreCuenta;
          localStorage.setItem('nombreCuenta', this.nombreCuenta || '');
          console.log('Nombre de la cuenta obtenido:', this.nombreCuenta);
          this.cargarDatosIniciales();
        } else {
          console.error('No se encontró nombreCuenta');
          this.router.navigate(['/perfil01']);
        }
      },
      error: (error) => {
        console.error('Error al obtener nombreCuenta', error);
        this.router.navigate(['/perfil01']);
      }
    });
  }

  cargarDatosIniciales() {
    this.http.get<any[]>('https://loginx.arcsacapital.com/inversiones_get')
      .pipe(
        tap(data => {
          if (data && data.length > 0) {
            const inversion = data[0];
            this.formSimulador.setValue({
              aumentoInversion: '',
              plazo_anios: inversion.plazo_anios
            });
            this.capitalInicial = parseFloat(inversion.monto_inversion);
            this.sumaCapital = this.capitalInicial; // Inicializar sumaCapital
            this.rendimientoPorcentaje = parseFloat(inversion.porcentaje);
            this.calcularRendimientos();
          }
        })
      ).subscribe();
  }

  get aumentoInversion() {
    return this.formSimulador.get('aumentoInversion');
  }

  onMontoFocus(event: any): void {
    let value = event.target.value;
    value = value.replace(/\$\s?|(,*)/g, '');
    this.formSimulador.patchValue({ aumentoInversion: value });
  }

  onMontoBlur(event: any): void {
    let value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      this.formSimulador.patchValue({
        aumentoInversion:
          value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }) + ' USD',
      });
    }
  }

  calcularRendimientos(): void {
    this.isLoading = true;
    this.escribiendo = false;

    setTimeout(() => {
      const aumentoInput = this.formSimulador.value.aumentoInversion;
      const aumentoInversion = aumentoInput ? Number(aumentoInput.replace(/[^\d.-]/g, '')) : 0;
      const plazo_anios = Number(this.formSimulador.value.plazo_anios) || 0;

      this.sumaCapital = this.capitalInicial + aumentoInversion;

      if (aumentoInversion && plazo_anios) {
        const porcentaje: { [key: number]: number } = {
          1: 16.5,
          2: 20,
          3: 20,
          4: 20,
          5: 20,
        };

        const porcentajeAnual = porcentaje[plazo_anios] || 0;

        this.rendimientoPorcentaje = porcentajeAnual;
        const rendimientoTotal = this.sumaCapital * (porcentajeAnual / 100) * plazo_anios;

        this.calculoAnual = rendimientoTotal / plazo_anios;
        this.calculoMensual = this.calculoAnual / 12;

        this.isLoading = false;
      } else {
        this.escribiendo = true;
      }
    });
  }

  capturarRendimientos() {
    if (this.formSimulador.invalid) {
      this.mensajeRespuesta = 'Por favor, completa el formulario correctamente.';
      return;
    }

    this.isLoading = true;
    const aumentoInversion = Number(this.formSimulador.value.aumentoInversion.replace(/[^\d.-]/g, ''));
    const plazo_anios = Number(this.formSimulador.value.plazo_anios);

    const token = this.authService.getToken();
    const nombreCuenta = localStorage.getItem('nombreCuenta') || '';
    if (!token) {
      this.mensajeRespuesta = 'No autorizado. Token no disponible.';
      this.isLoading = false;
      return;
    }

    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});

    const data = { 
      monto_incremento: aumentoInversion, 
      nombreCuenta: nombreCuenta
    };

    this.http.post('https://loginx.arcsacapital.com/incrementos', data, { headers, observe: 'response' })
      .pipe(
        tap((response: HttpResponse<any>) => {
          if (response.status === 201) {
            this.mensajeRespuesta = 'Datos insertados correctamente.';
            this.envioExitoso = true;
            this.tempDataService.clearFormAumentoInversion();
            this.router.navigate(['/perfil-aumento02']);
          }
        }),
        catchError((error) => {
          console.error('Error al enviar los datos:', error);
          this.mensajeRespuesta = error.error.message || 'Error al enviar los datos.';
          this.isLoading = false;
          this.envioExitoso = false;
          return of(null);
        })
      )
      .subscribe();
  }

  regresar() {
    this._location.back();
  }

  continuar() {
    this.capturarRendimientos();
  }
}