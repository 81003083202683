import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-perfil07-usuario',
  templateUrl: './perfil07-usuario.component.html',
  styles: []
})
export class Perfil07Component implements OnInit {
  title = 'Mi perfil';
  datosPersonales: any = null;
  errorMessage: string = '';

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.cargarDatosPersonales();
  }

  cargarDatosPersonales() {
    const url = 'https://loginx.arcsacapital.com/datos_personales/seccion1';
    this.http.get<any>(url).subscribe({
      next: (data) => {
        this.datosPersonales = data;
      },
      error: (error) => {
        console.error('Error al obtener los datos personales', error);
        this.errorMessage = 'Error al cargar los datos personales. Por favor intente más tarde.';
      }
    });
  }
}
