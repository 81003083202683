<div class="container">
  <div class="row d-flex justify-content-center">
    <div class="col-10 d-flex flex-column justify-content-center align-items-center mt-1">

      <h1 class="text-center mt-5">Bienvenido a</h1>
      <h2 class="text-center mt-1">Web App Arcsa Capital DEV</h2>

      <h4 class="text-center mt-5">Selecciona la sección que deseas probar</h4>
      
      <button routerLink="/onboarding01" routerLinkActive="active-link" class="btn btn-primary px-5 mt-4">Onboarding</button>
      <button routerLink="/preregistro01" routerLinkActive="active-link" class="btn btn-primary px-5 mt-4">Preregistro</button>
      <button routerLink="/simulador" routerLinkActive="active-link" class="btn btn-primary px-5 mt-4">Simulador</button>
      <button routerLink="/login" routerLinkActive="active-link" class="btn btn-primary px-5 mt-4">Login/Registro</button>
      <button routerLink="/comprobante01" routerLinkActive="active-link" class="btn btn-primary px-5 mt-4">Comprobante</button>
      <button routerLink="/contrato01" routerLinkActive="active-link" class="btn btn-primary px-5 mt-4">Contrato</button>
      <button routerLink="/perfil01" routerLinkActive="active-link" class="btn btn-primary px-5 mt-4">Perfil</button>

    </div>
  </div>
</div>