import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../services/logged/auth.service';
import { TempDataService } from '../../../services/temp-data.service';
import { catchError, tap, of, throwError } from 'rxjs';

@Component({
  selector: 'app-registro02',
  templateUrl: './registro02.component.html',
  styleUrls: []
})
export class Registro02Component implements OnInit {
  
  title = "Confirma tus datos";
  revisarFormDatos: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  isSubmissionSuccessful: boolean = false;
  isLoading: boolean | undefined;
  mensajeRespuesta: string | undefined;
  envioExitoso: boolean | undefined;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private _location: Location,
    private tempDataService: TempDataService,
  ) {
    this.revisarFormDatos = new FormGroup({
      nombres: new FormControl('', [Validators.required]),
      apellidos: new FormControl('', [Validators.required]),
      genero: new FormControl('', [Validators.required]),
      fechanac: new FormControl('', [Validators.required]),
      nacionalidad: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.tempDataService.getFormDatosPersonales().subscribe(data => {
      if (data) {
        this.revisarFormDatos.patchValue(data);
      } else {
        this.router.navigate(['/registro01']);
      }
    });
  }

  onSubmit() {
    if (this.revisarFormDatos.valid) {
      this.http.post('https://loginx.arcsacapital.com/datos_personales/seccion1', this.revisarFormDatos.value, { observe: 'response' })
        .pipe(
          tap((response: HttpResponse<any>) => {
            // Manejo del caso exitoso
            this.mensajeRespuesta = 'Información enviada con éxito, a pesar del error.';
            this.envioExitoso = true;
            this.isLoading = false;
            // Redirección a pesar del error
            this.router.navigate(['/registro03']);
          }),
          catchError((error: HttpErrorResponse) => {
            // Aquí puedes verificar el tipo de error
            if (error.status === 500) {
              // Manejo específico del error 500
              this.mensajeRespuesta = 'Error 500, pero procediendo con el flujo.';
              this.isLoading = false;
              // Redirección específica en caso de error 500
              this.router.navigate(['/registro03']);
              return of(null); // Finaliza el observable sin emitir un error
            } else {
              // Manejo de otros errores
              this.errorMessage = error.error.message || 'Hubo un error al enviar tus datos. Por favor, inténtalo de nuevo.';
              this.isLoading = false;
              this.envioExitoso = false;
              return throwError(() => new Error('Algo salió mal, por favor intenta de nuevo más tarde.'));
            }
          })
        )
        .subscribe();
    }
  }
  
  onEdit() {
    this.router.navigate(['/registro01']);
  }

  regresar(){
    this._location.back();
  }

}