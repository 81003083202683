import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { passwordValidator } from '../../../services/password-validators';

@Component({
  selector: 'app-preregistro03',
  templateUrl: './preregistro03.component.html',
  styleUrls: [],
})

export class Preregistro03Component{

  title = "Datos de Contacto";
  formContacto: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  isSubmissionSuccessful: boolean = false;
  isPasswordVisible: boolean = false;

  get nombre_usuario(){
    return this.formContacto.get('nombre_usuario');
  }
  get codigo_invitado(){
    return this.formContacto.get('codigo_invitado');
  }
  get telefono_movil(){
    return this.formContacto.get('telefono_movil');
  }
  get email(){
    return this.formContacto.get('email');
  }
  get contrasena(){
    return this.formContacto.get('contrasena');
  }
  get acepta_terminos(){
    return this.formContacto.get('acepta_terminos');
  }
  get acepta_aviso(){
    return this.formContacto.get('acepta_aviso');
  }

  constructor(
    private _location: Location,
    private router: Router,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
    ) {
    this.formContacto = new FormGroup({
      nombre_usuario: new FormControl('', [Validators.required]),
      codigo_invitado: new FormControl(''),
      telefono_movil: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
      email: new FormControl('', [Validators.required, Validators.email]),
      contrasena: new FormControl('', [Validators.required, passwordValidator()]),
      acepta_terminos: new FormControl('', [Validators.required]),
      acepta_aviso: new FormControl('', [Validators.required]),
    });

    this.setupValueChanges();
  }

  setupValueChanges() {
    Object.keys(this.formContacto.controls).forEach(key => {
      const control = this.formContacto.get(key);
      control?.valueChanges.subscribe(() => {
        this.errorMessage = '';
        this.successMessage = '';
      });
    });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onSubmit(){

    if (this.formContacto.valid) {
      const formData = this.formContacto.value;
      this.http.post<any>('https://loginx.arcsacapital.com/register', formData, { observe: 'response' })
        .pipe(
          tap((response: HttpResponse<any>) => {
            if (response.status === 201) {
              this.successMessage = 'Registro exitoso. Por favor, revise su email';
              this.errorMessage = '';
              this.isSubmissionSuccessful = true;
              setTimeout(() => this.router.navigate(['/preregistro04']), 2000);
            }
          }),
          catchError((error: HttpErrorResponse) => {
            this.isSubmissionSuccessful = false;
            if (error.status === 400) {
              this.errorMessage = "El correo ya se encuentra registrado.";
            } else {
              this.errorMessage = "Error al realizar el registro. Por favor, intente nuevamente.";
            }
            return of(null);
          })
        )
        .subscribe();
    }
  }

  regresar(){
    this._location.back();
  }

}