import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TempDataService } from '../../../services/temp-data.service';

@Component({
  selector: 'app-perfil03',
  templateUrl: './perfil03.component.html',
  styles: []
})
export class Perfil03Component implements OnInit {
  title = "Información de mi contrato";
  nombreCuenta: string | null = null;
  plazoAnios: number | null = null;
  porcentaje: number | null = null;

  cliente: string = '--';
  contrato: string = '--';
  titular: string = '--';
  fechaInicio: string = '--';
  fechaTermino: string = '--';

  nombres: string = '';
  apellidos: string = '';

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private tempDataService: TempDataService
  ) { }

  ngOnInit(): void {
    this.obtenerNombreCuenta();
    this.obtenerDatosPersonalesSeccion1();
  }

  obtenerNombreCuenta() {
    // Intentar recuperar nombreCuenta de localStorage
    const storedNombreCuenta = localStorage.getItem('nombreCuenta');
    if (storedNombreCuenta) {
      this.nombreCuenta = storedNombreCuenta;
      console.log('nombreCuenta recuperado de localStorage:', this.nombreCuenta);
      this.cargarDetallesInversion(this.nombreCuenta);
    } else {
      // Si no está en localStorage, intentar obtenerlo del servicio
      this.tempDataService.getFormDatosPersonales().subscribe({
        next: (data) => {
          if (data && data.nombreCuenta) {
            this.nombreCuenta = data.nombreCuenta;
            if (this.nombreCuenta !== null) {
              localStorage.setItem('nombreCuenta', this.nombreCuenta);
              console.log('nombreCuenta recuperado del servicio y almacenado en localStorage:', this.nombreCuenta);
              this.cargarDetallesInversion(this.nombreCuenta);
            }
          } else {
            console.error('No se encontró nombreCuenta');
            this.router.navigate(['/perfil01']);
          }
        },
        error: (error) => {
          console.error('Error al obtener nombreCuenta', error);
          this.router.navigate(['/perfil01']);
        }
      });
    }
  }

  cargarDetallesInversion(nombreCuenta: string) {
    const url = `https://loginx.arcsacapital.com/inversiones_get?nombreCuenta=${nombreCuenta}`;
    this.http.get<{plazo_anios: number, porcentaje: number}[]>(url).subscribe({
      next: (response) => {
        if (response.length > 0 && response[0].hasOwnProperty('plazo_anios') && response[0].hasOwnProperty('porcentaje')) {
          this.plazoAnios = response[0].plazo_anios;
          this.porcentaje = Math.round(response[0].porcentaje);
          console.log('Datos de inversión:', this.plazoAnios, 'años', this.porcentaje, '%');
        } else {
          console.error('No se encontraron los datos de inversión en la respuesta.');
        }
      },
      error: (error) => {
        console.error('Error al obtener los detalles de la inversión', error);
      }
    });
  }

  obtenerDatosPersonalesSeccion1() {
    this.http.get<{nombres: string, apellidos: string}>('https://loginx.arcsacapital.com/datos_personales/seccion1').subscribe({
      next: (data) => {
        this.nombres = data.nombres;
        this.apellidos = data.apellidos;
      },
      error: (error) => {
        console.error('Error al obtener datos personales sección 1:', error);
      }
    });
  }
}
