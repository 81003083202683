import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/logged/auth.service';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service';

@Component({
  selector: 'app-registro08',
  templateUrl: './registro08.component.html',
  styleUrls: []
})
export class Registro08Component implements OnInit {

  title = "Promesa de inversión";
  formPromesa: FormGroup;
  isLoading = false;
  successMessage: string | null = null;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private progresoUsuarioService: ProgresoUsuarioService,
  ) {
    this.formPromesa = this.fb.group({
      acepta_compromiso: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.progresoUsuarioService.checkUserProgressAndNavigate();
    this.formPromesa = this.fb.group({
      acepta_compromiso: [false, Validators.requiredTrue]
    });
  }

  onSubmit() {
    if (this.formPromesa.valid) {
      this.isLoading = true;
      const payload = { acepta_compromiso: this.formPromesa.value.confirmarPromesa };

      this.http.post('https://loginx.arcsacapital.com/datos_personales/seccion7', payload, { observe: 'response' })
        .subscribe({
          next: (response) => {
            if (response.status === 201) {
              this.successMessage = 'Promesa de inversión enviada con éxito. Redirigiendo...';
              this.isLoading = false;

              setTimeout(() => {
                this.router.navigate(['/registro09']); // Ajusta según la siguiente ruta necesaria
              }, 3000);
            } else {
              this.errorMessage = 'Respuesta inesperada del servidor: ' + response.status;
              this.isLoading = false;
            }
          },
          error: (error: HttpErrorResponse) => {
            this.errorMessage = error.error.message || 'Error al enviar los datos. Inténtalo de nuevo.';
            this.isLoading = false;
          }
        });
    } else {
      this.errorMessage = 'Debe aceptar el compromiso para continuar.';
      this.isLoading = false;
    }
  }
}
