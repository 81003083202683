import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../services/logged/auth.service';
import { TempDataService } from '../../../services/temp-data.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-registro07',
  templateUrl: './registro07.component.html',
  styleUrls: []
})
export class Registro07Component implements OnInit {
  title = "Confirma los datos de tus beneficiarios";
  beneficiarios: any[] = [];
  isLoading = false;
  errorMessage: string = '';
  successMessage: string = '';
  nombreCuentaTemp: string | null = null;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private tempDataService: TempDataService
  ) {}

  ngOnInit() {
    this.fetchAccountName();
    this.tempDataService.getFormBeneficiarios().subscribe(data => {
      console.log('Received data:', data);
      if (!data || data.beneficiarios.length === 0) {
        console.error('No data available, redirecting back.');
        this.location.back();
      } else {
        this.beneficiarios = data.beneficiarios;
        console.log('Data loaded:', this.beneficiarios);
      }
    });
  }

  fetchAccountName() {
    this.isLoading = true;
  
    this.http.get<any[]>('https://loginx.arcsacapital.com/cuentas_get')
      .pipe(
        tap(data => {
          if (data.length > 0 && data[0].hasOwnProperty('nombreCuenta')) {
            this.nombreCuentaTemp = data[0].nombreCuenta;
            console.log('Nombre de cuenta obtenido:', this.nombreCuentaTemp);
          } else {
            throw new Error('Error: No se encontró el campo nombreCuenta o el array está vacío.');
          }
        }),
        catchError(error => {
          console.error('Error al realizar la solicitud GET:', error);
          return of(null);
        }),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: () => {
          console.log('Operación realizada con éxito.');
        },
        error: (err) => {
          console.error('Error en el proceso de obtención del nombreCuenta:', err);
        }
      });
  }
  
  onSubmit() {
    if (this.beneficiarios.length > 0) {
      this.isLoading = true;
      const token = this.authService.getToken() || '';
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token 
      });

      if (!this.nombreCuentaTemp) {
        this.errorMessage = 'No autorizado o nombreCuenta no disponible.';
        this.isLoading = false;
        return;
      }

      const beneficiariosConNombreCuenta = this.beneficiarios.map(beneficiario => ({
        ...beneficiario,
        nombreCuenta: this.nombreCuentaTemp // Asegura que nombreCuenta se añada a cada beneficiario
      }));

      this.http.post('https://loginx.arcsacapital.com/datos_personales/seccion6', { beneficiarios: beneficiariosConNombreCuenta }, { headers: headers, observe: 'response' })
        .subscribe({
          next: (response) => {
            if (response.status === 201) {
              this.successMessage = 'Datos enviados correctamente. Redirigiendo...';
              this.isLoading = false;
  
              setTimeout(() => {
                this.router.navigate(['/registro08']);
              }, 3000);
            } else {
              this.errorMessage = 'Respuesta inesperada del servidor: ' + response.status;
              this.isLoading = false;
            }
          },
          error: (error: HttpErrorResponse) => {
            this.errorMessage = error.error.message || 'Error al enviar los datos. Inténtalo de nuevo.';
            this.isLoading = false;
          }
        });
    } else {
      this.errorMessage = 'No hay beneficiarios para enviar.';
    }
  }

  onEdit() {
    this.router.navigate(['/registro06']);
  }
}
